<template>
  <div class='container'>
    <div
      v-if="submited==true"
      class="auth-wrapper auth-v1"
    >
      <v-progress-circular
        :size="100"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div
      v-else
    >
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :top="true"
        :right="true"
        transition="scroll-x-reverse-transition"
      >
        {{ snackbar.message }}
      </v-snackbar>

      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :top="true"
        :right="true"
        transition="scroll-x-reverse-transition"
      >
        {{ snackbar.message }}
      </v-snackbar>
      <v-card width='700px'>

        <v-card-title class="d-flex align-center justify-center mt-4 py-2">
          <v-img
            :src="require('@/assets/images/logos/corp_logo.svg')"
            max-height="100px"
            max-width="100px"
            alt="logo"
            contain
            class="me-3 logo"
          ></v-img>
          <router-link
            to="/"
            class="d-flex align-center"
          >
          </router-link>
        </v-card-title>
        <v-card-title class="d-flex align-center justify-center mt-4 py-2">
          <b> Réinitialisation du mot de passe! </b>
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="pt-10">
          <v-form
            id="ressetForm"
            ref="ressetForm"
            class="multi-col-validation col-md-10 offset-md-1"
          >
            <v-row class="mx-auto">
              <v-col
                cols="12  "
                md="12"
              >
                <v-text-field
                  v-model="password"
                  label="Nouveau mot de passe"
                  :error-messages="errors.password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :rules="pwdRules"
                  @copy="onCopy"
                  @cut="onCut"
                  @paste="onPaste"
                  outlined
                  dense
                  placeholder="Nouveau mot de passe "
                  hide-details="auto"
                  class="mb-2 input"
                  :class="{ 'is-invalid': passwordError, 'is-valid': !passwordError }"
                  @input="checkPassword"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
                <h6
                  v-for='item in regles'
                  v-if='password && passwordError'
                >
                  <span :class='item.validation?"successMessage":""'
                        style='font-weight: normal'
                  >
                    {{item.message}}
                  </span>
                </h6>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="passwordConfirmation"
                  :error-messages="errors.passwordConfirmation"
                  :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                  label="Confirmer mot de passe"
                  :rules="pwdRules"
                  @copy="onCopy"
                  @cut="onCut"
                  @paste="onPaste"
                  outlined
                  dense
                  placeholder="Confirmer mot de passe "
                  hide-details="auto"
                  class="mb-4 input"
                  @input="checkPassword"
                  :disabled="!passwordConditionsMet"
                  :class="{ 'is-invalid': passwordConfirmationError, 'is-valid': !passwordConfirmationError }"
                  v-if="!passwordError"
                  :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                ></v-text-field>
              </v-col>
              <div v-if="passwordConfirmationError">{{passwordConfirmationError}}</div>
            </v-row>
            <br>
            <v-row
              class="mb-10"
            >
              <v-col
                cols="12"
                class="d-sm-flex flex-sm-row-reverse"
              >
                <v-btn
                  color="primary"
                  @click="savePW"
                  class='text-capitalize'
                >
                  <span> Réinitialiser </span>
                </v-btn>
                <v-btn
                  type="button"
                  outlined
                  @click="resetForm"
                  class='text-capitalize mr-2'
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  mdiLock,
  mdiEyeOffOutline,
  mdiEyeOutline,
} from '@mdi/js'
import {useRouter} from '@core/utils'
import { onBeforeMount, reactive, ref } from '@vue/composition-api'
import Http from '@/helpers/http'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import moment from 'moment/moment'


export default {
  name: 'RessetPW',
  setup() {
    // Règle de validation d'un champ
    const pwdRules = [
      v => !!v || 'Le password est réquis',
      v => (v && v.length <= 20) || 'Le mot de passe doit avoir moins de 20 caractères',
    ]

    const ressetPW = reactive({})
    const isPasswordVisible = ref(false)
    // eslint-disable-next-line camelcase
    const isPasswordConfirmationVisible = ref(false)
    const password = ref('')
    const passwordConfirmation = ref('')
    const errors = ref({})

    const submited = ref('false')
    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
      timeout: 15000,
    })

    const regles = [
      {
      message:'Le mot de passe doit commencer par une lettre majuscule.',
      validation : true
    },  {
      message:'Le mot de passe doit contenir au moins une lettre minuscule.',
      validation : true
    },  {
      message:'Le mot de passe doit contenir au moins un chiffre.',
      validation : true
    },  {
      message:'Le mot de passe doit contenir au moins un caractère spécial.',
      validation : true
    },{
      message:'Le mot de passe doit contenir au moins 6 caractères.',
      validation : true
    },
    ]

    return {
      pwdRules,
      ressetPW,
      snackbar,
      submited,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      password,
      passwordConfirmation: '',
      passwordError: '',
      passwordConfirmationError: '',
      passwordConditionsMet: false,
      errors,
      regles,

      icons: {
        mdiLock,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  computed: {
    applyRules() {
      if (this.ressetPW.typeDeposit !== 1) {
        return this.LocalNameRules
      }

      return []
    },
  },

  methods: {
    async savePW() {if (this.$refs.ressetForm.validate()) {
      if (this.password === this.passwordConfirmation) {
        console.log(this.ressetPW)

        const { pathname } = window.location

        let split= pathname.split("/");
        let token_email = split[2]

        const params = { password: this.password, password_confirmation: this.passwordConfirmation, token: token_email }
        console.log(params)
        Http.post(
          'password-reset',
          params,
        ).then(rep => {
          if (rep.status === 200) {
            router.push('/login')
          }
        }).catch(error => {
          // eslint-disable-next-line no-undef
          submited.value = false
          if (error.status === 422) {
            this.snackbar.message = error.data.errors
          } else if (error.status === 401) {
            this.snackbar.message = error.data
          } else {
            this.snackbar.message = 'Votre connexion internet est instable'
          }
          this.snackbar.color = 'error'
          this.snackbar.show = true
        })
      } else {
        this.snackbar.message = 'Les mots de passe passe ne correspondent pas '
        this.snackbar.color = 'error'
        this.snackbar.show = true
      }
        }
      else {
        console.log('erererer')
      }
    },
    checkPassword() {
      let compteur = 0
      this.regles = [
        {
          message:'Le mot de passe doit commencer par une lettre majuscule.',
          validation : false
        },  {
          message:'Le mot de passe doit contenir au moins une lettre minuscule.',
          validation : false
        },  {
          message:'Le mot de passe doit contenir au moins un chiffre.',
          validation : false
        },  {
          message:'Le mot de passe doit contenir au moins un caractère spécial.',
          validation : false
        },{
          message:'Le mot de passe doit contenir au moins 6 caractères.',
          validation : false
        },
      ]
      // Vérifier si le mot de passe respecte les conditions requises
        if (/^[A-Z]/.test(this.password)) {
          this.passwordError = 'Le mot de passe doit commencer par une lettre majuscule.'
          this.regles[0].validation = true;
          compteur = compteur + 1
        }
        if (/[a-z]/.test(this.password)) {
          this.passwordError = 'Le mot de passe doit contenir au moins une lettre minuscule.'
          this.regles[1].validation = true;
          compteur = compteur + 1
        }
        if (/\d/.test(this.password)) {
          this.passwordError = 'Le mot de passe doit contenir au moins un chiffre.'
          this.regles[2].validation = true;
          compteur = compteur + 1
        }
        if (/[\W_]/.test(this.password)) {
          this.passwordError = 'Le mot de passe doit contenir au moins un caractère spécial.'
          this.regles[3].validation = true
          compteur = compteur + 1
        }
        if (this.password.length > 6) {
          this.passwordError = 'Le mot de passe doit contenir au moins 6 caractères.'
          this.regles[4].validation = true
          this.passwordConditionsMet = this.password.length >= 6
          compteur = compteur + 1
        }
        if (compteur === 5){
          this.passwordError =''
        }


      // Vérifier si la confirmation du mot de passe est identique au mot de passe
      if (this.password !== this.passwordConfirmation) {
        this.passwordConfirmationError = 'Les mots de passe ne correspondent pas.'
      } else {
        this.passwordConfirmationError = ''
      }
    },
    onCopy(event) {
      event.preventDefault()
    },
    onCut(event) {
      event.preventDefault();
    },
    onPaste(event) {
      event.preventDefault();
    },
    resetForm() {
      this.$refs.ressetForm.reset()
    },
  },
}
</script>
<style lang="scss">
@import '~@core/preset/preset/pages/auth.scss';
.errorMessage {
  color: #f20808 !important;
}
.successMessage {
  color: green !important;
}
.Message {
  color: black !important;
}
.is-invalid {
  border-color: red;
}
.is-valid {
  border-color: green;
}
.fa-spinner {
  animation: fa-spin 1s infinite linear;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
